import './fairy-service.css';

export const fairies = {
  show: showFairy,
  hide: hideFairy,
};

/**
 * Hide a fairy.
 *
 * @param id : a unique identifier for the fairy
 */
function hideFairy(id) {
  const fairyEl = document.querySelector(`#${id}`);
  if (fairyEl) {
    fairyEl.parentNode.removeChild(fairyEl);
  }

  const popoverEl = document.querySelector(`#${id}-popover`);
  if (popoverEl) {
    popoverEl.parentNode.removeChild(popoverEl);
  }
}

/**
 * Show a fairy.
 *
 * @param id : a unique identifier for the fairy
 * @param parentElement : element in which to insert the fairy
 * @param content : content for the popover
 * @param top : css top property for the fairy
 * @param left : css left property for the fairy
 * @param popoverTop : css top property for the popover
 * @param popoverLeft : css left property for the popover
 */
function showFairy(id, parentElement, content, top, left, popoverTop, popoverLeft, zIndex) {
  if (shouldShowFairy(id)) {
    const zIndexStr = typeof zIndex === 'number' ? ` z-index: ${zIndex};` : ``;
    var fairyHtml =
      `<div id="${id}" class="cp-fairy" style="top: ${top}px; left: ${left}px;${zIndexStr}">` +
      '<div class="cp-fairy__dot"></div>' +
      '<div class="cp-fairy__pulse"></div>' +
      '</div>';

    var popoverHtml =
      '<div id="' + id + '-popover" class="cp-fairy-popover" style="top: ' + popoverTop + 'px; left: ' + popoverLeft + 'px;">' +
      '<div class="cp-fairy-popover__content">' + content + '</div>' +
      '<a href="#" class="cp-fairy-popover__close" id="' + id + '-popover-close">DONE</a>' +
      '</div>';

    var fairy = document.createElement('div')
    fairy.innerHTML = fairyHtml;

    var popover = document.createElement('div');
    popover.setAttribute('style', 'display: none;');
    popover.innerHTML = popoverHtml;

    parentElement.appendChild(fairy);
    parentElement.appendChild(popover);

    bindEventHandlers(id, fairy, popover);
  } else {
    dismissFairy(id); // Extend the cookie's expiration date just in case
  }
}

function bindEventHandlers(id, fairy, popover) {
  fairy.onclick = function(e) {
    toggleVisible(popover);
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }

  document.getElementById(id + '-popover-close').onclick = function(e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    hide(popover);
    hide(fairy);
    dismissFairy(id);
  }
}

function dismissFairy(id) {
  var key = 'cp-fairy-' + id + '-dismissed';
  localStorage.setItem(key, true);
}

function shouldShowFairy(id) {
  var key = 'cp-fairy-' + id + '-dismissed';
  return !localStorage.getItem(key);
}

function toggleVisible(el) {
  if (el.getAttribute('style') && el.getAttribute('style').indexOf('display: none;') > -1) {
    show(el);
  } else {
    hide(el);
  }
}

function show(el) {
  el.setAttribute('style', el.getAttribute('style').replace('display: none;', ''));
}

function hide(el) {
  var newStyle = el.getAttribute('style') ? el.getAttribute('style') + ' display: none;' : 'display: none;';
  el.setAttribute('style', newStyle);
}
